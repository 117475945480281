@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fraunces:ital,opsz,wght@0,9..144,100..900;1,9..144,100..900&display=swap');

.no-mobile{
  display:block;
}

.App{
  display:flex;
  justify-content: center;
  margin-top:15vh;
}

@media only screen and (max-width: 600px) {
  .App{
    margin-top:5vh;
  }
}